import React from "react";
import theme from "theme";
import { Theme, Text, Box, Image, LinkBox, Section, Button } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"about"} />
		<Helmet>
			<title>
				Про нас | Фітнес-Центр Енергія
			</title>
			<meta name={"description"} content={"Дізнайтеся більше про команду Фітнес-Центру Енергія, нашу місію, цінності та підходи до фітнесу."} />
			<meta property={"og:title"} content={"Про нас | Фітнес-Центр Енергія"} />
			<meta property={"og:description"} content={"Дізнайтеся більше про команду Фітнес-Центру Енергія, нашу місію, цінності та підходи до фітнесу."} />
			<link rel={"shortcut icon"} href={"https://brightsidewix.com/img/4729230.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://brightsidewix.com/img/4729230.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://brightsidewix.com/img/4729230.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://brightsidewix.com/img/4729230.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://brightsidewix.com/img/4729230.png"} />
			<meta name={"msapplication-TileImage"} content={"https://brightsidewix.com/img/1.jpg"} />
			
		</Helmet>
		<Components.Header />
		<Section padding="80px 0 80px 0" lg-padding="60px 0 60px 0" md-padding="40px 0 40px 0" sm-padding="30px 0 30px 0">
			<Box margin="0px 0px 32px 0px" display="flex" flex-direction="column" align-items="flex-start">
				<Text
					font="--headline1"
					color="--dark"
					text-align="center"
					md-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					margin="16px 0px 0px 0px"
				>
					Про нас
				</Text>
				<Text font="--lead" color="--darkL2" max-width="1100px">
				Дізнайтеся більше про нашу команду, місію та цінності. Ми прагнемо допомогти вам досягти ваших фітнес-цілей і зробити спорт невід'ємною частиною вашого життя.
				</Text>
			</Box>
			<LinkBox
				display="flex"
				margin="0px 0px 32px 0px"
				md-flex-direction="column"
				md-align-items="center"
				md-justify-content="center"
				flex-direction="row"
				align-items="flex-start"
				flex-wrap="wrap"
				lg-margin="0px 0px 16px 0px"
			>
				<Box
					width="60%"
					padding="0px 16px 0px 0px"
					align-items="flex-start"
					display="flex"
					justify-content="flex-start"
					margin="0px 0px 0px 0px"
					md-width="100%"
					lg-width="100%"
					lg-padding="0px 0px 0px 0px"
				>
					<Image
						src="https://brightsidewix.com/img/13.jpg"
						border-radius="24px"
						max-width="100%"
						max-height="456px"
						width="100%"
						object-fit="none"
						lg-max-height="392px"
						sm-max-height="213px"
					/>
				</Box>
				<Box
					width="40%"
					padding="0px 0px 0px 16px"
					display="flex"
					align-items="flex-start"
					justify-content="flex-start"
					md-width="100%"
					sm-align-items="flex-start"
					sm-flex-direction="column"
					sm-justify-content="flex-start"
					flex-direction="column"
					lg-width="100%"
					lg-flex-direction="row"
					lg-justify-content="space-between"
					lg-padding="16px 12px 16px 12px"
				>
					<Box padding="0px 50px 0px 0px" lg-width="70%" sm-width="100%" lg-padding="0px 0px 0px 0px">
						<Text margin="0px 0px 8px 0px" font="--headline3" color="--dark">
						Наша місія
						</Text>
						<Text margin="0px 0px 0px 0px" font="--lead" color="--darkL2">
						Фітнес-Центр "Енергія" був створений з метою допомогти людям покращити своє здоров'я, фізичну форму та загальний добробут. Ми віримо, що регулярні заняття спортом сприяють не лише фізичному, а й психологічному здоров'ю.
						</Text>
					</Box>
				</Box>
			</LinkBox>
			<LinkBox
				display="flex"
				margin="0px 0px 32px 0px"
				md-flex-direction="column"
				md-align-items="center"
				md-justify-content="center"
				flex-direction="row"
				align-items="flex-start"
				flex-wrap="wrap"
				lg-margin="0px 0px 16px 0px"
			>
			<Box
				width="60%"
				padding="0px 0px 0px 16px"
				display="flex"
				align-items="flex-start"
				justify-content="flex-start"
				md-width="100%"
				sm-align-items="flex-start"
				sm-flex-direction="column"
				sm-justify-content="flex-start"
				flex-direction="column"
				lg-width="100%"
				lg-flex-direction="row"
				lg-justify-content="space-between"
				lg-padding="16px 12px 16px 12px"
			>
				<Box padding="0px 50px 0px 0px" lg-width="70%" lg-padding="0px 0px 0px 0px" sm-width="100%">
					<Text margin="0px 0px 8px 0px" font="--headline3" color="--dark">
					Наша команда
					</Text>
					<Text margin="0px 0px 0px 0px" font="--lead" color="--darkL2">
					Ми пишаємося нашою командою професіоналів. Кожен наш тренер має відповідну освіту та сертифікацію, а також багаторічний досвід роботи. Вони завжди готові допомогти вам досягти ваших цілей та підтримати на кожному етапі тренувального процесу.
					</Text>
				</Box>
			</Box>
				<Box
					width="40%"
					padding="0px 16px 0px 0px"
					align-items="flex-start"
					display="flex"
					justify-content="flex-start"
					margin="0px 0px 0px 0px"
					md-width="100%"
					lg-width="100%"
					lg-padding="0px 0px 0px 0px"
				>
					<Image
						src="https://brightsidewix.com/img/14.jpg"
						border-radius="24px"
						max-width="100%"
						max-height="456px"
						width="100%"
						object-fit="none"
						lg-max-height="392px"
						sm-max-height="213px"
					/>
				</Box>
			</LinkBox>
			<LinkBox
				display="flex"
				margin="0px 0px 32px 0px"
				md-flex-direction="column"
				md-align-items="center"
				md-justify-content="center"
				flex-direction="row"
				align-items="flex-start"
				flex-wrap="wrap"
				lg-margin="0px 0px 16px 0px"
			>
				<Box
					width="60%"
					padding="0px 16px 0px 0px"
					align-items="flex-start"
					display="flex"
					justify-content="flex-start"
					margin="0px 0px 0px 0px"
					md-width="100%"
					lg-width="100%"
					lg-padding="0px 0px 0px 0px"
				>
					<Image
						src="https://brightsidewix.com/img/15.jpg"
						border-radius="24px"
						max-width="100%"
						max-height="456px"
						width="100%"
						object-fit="none"
						lg-max-height="392px"
						sm-max-height="213px"
					/>
				</Box>
				<Box
					width="40%"
					padding="0px 0px 0px 16px"
					display="flex"
					align-items="flex-start"
					justify-content="flex-start"
					md-width="100%"
					sm-align-items="flex-start"
					sm-flex-direction="column"
					sm-justify-content="flex-start"
					flex-direction="column"
					lg-width="100%"
					lg-flex-direction="row"
					lg-justify-content="space-between"
					lg-padding="16px 12px 16px 12px"
				>
					<Box padding="0px 50px 0px 0px" lg-width="70%" lg-padding="0px 0px 0px 0px" sm-width="100%">
						<Text margin="0px 0px 8px 0px" font="--headline3" color="--dark">
						Наші цінності
						</Text>
						<Text margin="0px 0px 0px 0px" font="--lead" color="--darkL2">
						Ми цінуємо здоров'я, професіоналізм та індивідуальний підхід до кожного клієнта. Наша мета - створити комфортну та дружню атмосферу, де кожен зможе займатися спортом з радістю та задоволенням.
						</Text>
					</Box>
				</Box>
			</LinkBox>
		</Section>
		<Section padding="64px 0 64px 0" md-padding="36px 0 36px 0" lg-padding="48px 0 48px 0" quarkly-title="Images-20">
			<Override slot="SectionContent" max-width="960px" />
			<Box
				align-items="center"
				sm-flex-direction="column-reverse"
				sm-align-items="center"
				quarkly-title="Card"
				display="flex"
				justify-content="space-between"
			>
				<Box sm-margin="0px 0px 0px 0px" max-width="450px" margin="0px 8% 0px 0px" md-margin="0px 24px 0px 0px">
					<Text font="--lead" sm-text-align="left">
					Зв'яжіться з нами, щоб дізнатися більше про наші послуги та записатися на тренування. Ми завжди раді новим клієнтам.
					</Text>
					<Button href="/contact-us" type="link" text-decoration-line="initial">
						Контакти
					</Button>
				</Box>
				<Image
					md-width="50%"
					sm-margin="0px 0px 24px 0px"
					align-self="flex-end"
					src="https://brightsidewix.com/img/16.jpg"
					object-fit="cover"
					width="45%"
					flex="0 0 auto"
				/>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});